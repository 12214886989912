import '../../App.css';
import React, {Component, Container} from 'react';
import {Row,Col,Image,Navbar, Nav, NavItem, NavDropdown } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import YoutubeBackground from 'react-youtube-background';
import Card from './card';
import NavBarTop from "./navbar";
import Footer from "./footer";

var count = []
const requesturl = "https://docs.google.com/forms/d/e/1FAIpQLSe8zC_TpbBeclJsu2Py8qRX6YFUZcylAlfdXt-kUnyYcSU93g/viewform?usp=preview"

class IESADScholarship extends Component {
  constructor(props){
    super(props);
    this.state = {
       text:{
        "title":"Summer English in Oxford Programme",
        "body":"Transcend your english with teachers in Oxford this summer. Be surrounded in a university city with heritage over a millennium.\n\r Date: Mid June - Early September 2025, you may start at any week in between. \n\r Details of the Program are displayed in the magazine below. We are currently offering a quota of 20 participants. If you are interested, please fill in your contact by using the button below.\n\r",
       }
    };

    this.setval = this.setval.bind(this)
  }

  setval(obj){
    this.setState(obj)
  }
  componentDidMount(){
    document.title="International English Speech Art and Drama Competition"
    var link1 = document.getElementById('favicon')
    var link2 = document.getElementById('touchicon')
    link1.href="/assets/iesad_logo.png"
    link2.href="/assets/iesad_logo.png"
    this.props.setval({comp:"iesad"}) 
  }      
      
  render(){
    for(let i=1;i<=6;i++){
      count[i] = i

    }
    return (
      <div>

        <div>
            <img src="/assets/iesad-scholarship-banner.jpg" alt="" width="100%" style={{borderRadius:"0px"}}/>
        </div>

        <div className="home-card" style={{width:"95vw",padding:"40px"}}>
          <div className="cardcontent" style={{fontSize:"0.9rem"}}>
                  <div className="about-text" style={{fontSize:"2rem",fontWeight:"bolder",marginBottom:"40px"}} >
                    {this.state.text["title"]}</div>
                  {
                      this.state.text["body"].split('\n\r').map(
                      (paragraph) => {
                      return <p className="about-text">{paragraph}</p>;
                      })
                  }
          </div>

          <div style={{display:"flex",flexDirection:"column",marginLeft:"20%",marginRight:"20%"}}>
                  <a><button className="about-goldbutton" style={{width:"100%"}} onClick={(e)=>{
                      window.open(requesturl, '_blank', 'noopener,noreferrer');
                  }}>{"Request Info and Prices"}</button></a>
                  </div>
          
        </div>

        <div style={{marginTop:"50px"}}>
         {
           count.map((item)=>{
            return  <div className={"magazine-wrapper"}>
                <img src={"../assets/iesad-scholarship-magazine/"+parseInt(item)+".PNG"} className='magazine-img' style={{borderRadius:"10px"}}/>
            </div>
           })
         }  
         </div>


      </div>
    );
  }
  }
  

export default IESADScholarship;
