import '../../App.css';
import React, {Component, Container} from 'react';
import {Row,Col,Image,Navbar, Nav, NavItem, NavDropdown } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import YoutubeBackground from 'react-youtube-background';
import Card from './card';
import NavBarTop from "./navbar";
import Footer from "./footer";
import Teacherlnfo from "./teacherinfo";
var count = []

class IYAOTeacherList extends Component {
  constructor(props){
    super(props);
    this.state = {
       
    };

    this.setval = this.setval.bind(this)
    this.goprofile = this.goprofile.bind(this)
  }

  setval(obj){
    this.setState(obj)
  }
  goprofile(teacherid,region){
      const teacher = Teacherlnfo[region][teacherid]
      this.props.setval({selectedTeacher:teacher})
  }
  componentDidMount(){
    document.title="International Young Artist Open Competition"
    var link1 = document.getElementById('favicon')
    var link2 = document.getElementById('touchicon')
    link1.href="/assets/iyao_logo.png"
    link2.href="/assets/iyao_logo.png"
    this.props.setval({comp:"iyao"}) 
  }   
  componentWillUnmount(){
    this.props.setval({selectedTeacher:null})
  }   
      
  render(){

    return (
      <div>

      {(this.props.selectedTeacher==undefined) &&
      <div className="home-content-div" style={{textAlign:"justify",textJustify:"inter-word", padding:"4% 6%",fontFamily:"Courgette"}}>
        

        <div className='winners-banner'>
          <img src='/assets/winnerteacherbanner.PNG' alt='Winner Teacher'  />
        </div>
        <h2 className='winners-title'>International Young Artist Open Competition</h2>
        <h5 className='winners-title'>Outstanding Music Teacher List</h5>

        <div style={{margin:"20px",textAlign:"justify"}}>
          <p>{"We deeply recognise efforts from teachers for their nurturing in a musician's journey. The list of Outstanding Music Teachers celebrate and mark the distinguished milestone of one's mentoring whose students also had the privilege to receive a winner prize."}</p>
        </div>


        <div style={{margin:"20px",textAlign:"justify"}}>

            <div style={{marginBottom:"15px",marginTop:"25px"}}>Hong Kong</div>
            {   (Teacherlnfo["hk"]!= undefined) &&
                Object.keys(Teacherlnfo["hk"]).map((key)=>{
                    return(
                        <div key={key}  className={"teacherlist-item"} onClick={(e)=>{
                          this.goprofile(key,"hk")
                        }}>
                            <em style={{fontSize:"1.4em",margin:"30px"}}>{Teacherlnfo["hk"][key]["name"]}</em>
                        </div>
                    )
                })
            }
        

            <div style={{marginBottom:"15px",marginTop:"25px"}}>Indonesia</div>
            {   (Teacherlnfo["in"]!= undefined) &&
                Object.keys(Teacherlnfo["in"]).map((key)=>{
                    return(
                        <div key={key}  className={"teacherlist-item"} onClick={(e)=>{
                          this.goprofile(key,"in")
                        }}>
                            <em style={{fontSize:"1.4em",margin:"30px"}}>{Teacherlnfo["in"][key]["name"]}</em>
                        </div>
                    )
                })
            }

            {/* <div style={{marginBottom:"15px",marginTop:"25px"}}>Malaysia</div>
            {   (Teacherlnfo["ml"]!= undefined) &&
                Object.keys(Teacherlnfo["ml"]).map((key)=>{
                    return(
                        <div key={key}  className={"teacherlist-item"}   onClick={(e)=>{
                          this.goprofile(key,"ml")
                        }}>
                            <em style={{fontSize:"1.4em",margin:"30px"}}>{Teacherlnfo["ml"][key]["name"]}</em>
                        </div>
                    )
                })
            } */}

            <div style={{marginBottom:"15px",marginTop:"25px"}}>South Africa</div>
            {   (Teacherlnfo["sa"]!= undefined) &&
                Object.keys(Teacherlnfo["sa"]).map((key)=>{
                    return(
                        <div key={key}  className={"teacherlist-item"} onClick={(e)=>{
                          this.goprofile(key,"sa")
                        }}>
                            <em style={{fontSize:"1.4em",margin:"30px"}}>{Teacherlnfo["sa"][key]["name"]}</em>
                        </div>
                    )
                })
            }


          <div style={{marginBottom:"15px",marginTop:"25px"}}>Thailand</div>
              {   (Teacherlnfo["th"]!= undefined) &&
                  Object.keys(Teacherlnfo["th"]).map((key)=>{
                      return(
                          <div key={key} className={"teacherlist-item"}  onClick={(e)=>{
                            this.goprofile(key,"th")
                          }}>
                              <em style={{fontSize:"1.4em",margin:"30px"}}>{Teacherlnfo["th"][key]["name"]}</em>
                          </div>
                      )
                  })
              }

        </div>

        <div style={{marginTop:"50px",fontSize:"0.8rem"}}>{"*The above list is arranged in alphabetical order."}</div>

           
        </div>
      }


      {(this.props.selectedTeacher!=undefined) &&
      
      <div style={{display:"flex",flexDirection:"column", marginBottom:"100px"}}>

        <div className={"about-goldbutton"} style={{display:"flex",flexDirection:"row",cursor:"pointer",width:"130px",marginLeft:"40px",marginTop:"40px",height:"60px"}} onClick={(e)=>{
          this.props.setval({selectedTeacher:null})
        }}>
          <img src="/assets/left-long-solid.svg" width={"15px"} height={"15px"} style={{margin:"5px"}} />
          <div style={{fontSize:"1rem"}}>&nbsp;&nbsp;&nbsp;{"Back"}</div>
        
        </div>

      <div className={"teacherlist-frame"}>

          <div className="jury-profile">
              <img src={"/assets/2024winnerteacher/"+this.props.selectedTeacher["name"]+".png"} alt="" width="100%" style={{padding:"30px"}} />
              </div>

          <div className="jury-text" style={{marginTop:"100px",marginRight:"80px"}}>
            <h4 style={{marginTop:"5px",marginBottom:"40px"}}><b>{this.props.selectedTeacher["name"]}</b></h4>
                {
                    this.props.selectedTeacher["profile"].split('\r\n').map(
                    (paragraph) => {
                    return <div>
                      <p className="about-text">{paragraph}</p>
                      <br></br>
                      </div>
                    
                    })
                }

          </div>
          
        </div>

     

  </div>

      }


      </div>
    );
  }
  }
  

export default IYAOTeacherList;
