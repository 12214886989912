import '../../App.css';
import React, {Component, Container} from 'react';
import {Row,Col,Image,Navbar, Nav, NavItem, NavDropdown, Dropdown } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import YoutubeBackground from 'react-youtube-background';
import Card from './card';
import NavBarTop from "./navbar";
import Footer from "./footer";
import PaypalCheckoutBtn from './elements/paypalcheckout';
import AwardCheckout from './elements/awardcheckout';
import laureate from '../../laureate';

const applyurl2 = "https://docs.google.com/forms/d/e/1FAIpQLScL6SXNm2_poouNdqxCYnupADYWFvF3umw4o1CPVK2A4GepWw/viewform" 
class IESADAwards extends Component {
  constructor(props){
    super(props);
    this.state = {
       itemType:"Please select your item",
       productDescription:null,
       awardPayment:0,
    };

    this.setval = this.setval.bind(this)
    this.clickapply = this.clickapply.bind(this)
  }

  clickapply(){
    window.open(applyurl2, '_blank', 'noopener,noreferrer');
}
  setval(obj){
    this.setState(obj)
  }
  componentDidMount(){
    document.title="International English Speech Art and Drama Competition"
    var link1 = document.getElementById('favicon')
    var link2 = document.getElementById('touchicon')
    link1.href="/assets/iesad_logo.png"
    link2.href="/assets/iesad_logo.png"
    this.props.setval({comp:"iesad"}) 
  }      
      
  render(){
    return (
      <div style={{width:"100%",padding:"1% 0%"}}><img src="/assets/award.png" alt="" width="100%" style={{borderRadius:"0px"}}/>
      <div className="home-content-div" style={{textAlign:"justify",textJustify:"inter-word", padding:"0% 7%",fontFamily:"Courgette"}}>
        
      

        {/* {"Insert award order here"} */}

     
          {/* {"Award Ends"} */}
        
        <h1 style={{marginTop:"4%",marginBottom:"1vh"}}>Awards</h1>

        <br/><h5>For teachers:</h5>
        <ul>
          <li>
            <h5><b>Excellent Mentorship Award</b> - Teachers of Champion, Second Prize, Third Prize and Fourth Prize winners</h5>
          </li>
          <li>
          <h5> <b>Active English Educator Award</b> - Any teacher who recommends four students or above across any groups</h5>
          </li>
          <li>
          <h5> <b>Teacher Medals</b> - Teachers with the number of Champion to 3rd Prize winners will be eligible to order our teacher medals:</h5>
                <ul>
                  <li>5 or above - Gold Medal</li>
                  <li>4 - Silver Medal</li>
                  <li>3 - Bronze Medal</li>
                </ul>
          </li>
        </ul>

        
        <br/>
        
        <h5>For centres and institutions:</h5>
        <ul>
          <li>
            <h5><b>Excellent Speech Art and Drama Institution Award</b> - Any organization which recommended 6 contestants or above across any groups</h5>
          </li>
          <li>
          <h5> <b>Elite Speech Art and Drama Institution Award</b> - Any organization with 4 or more contestants receiving Champion, 2nd, 3rd or 4th Prize</h5>
          </li>
        </ul>


        <br/>
        
        <h5>For students:</h5>

        <ul>
          <li>
          <h5><b>Champion to 4th place winners</b> - Contestants can order a reference letter and be invited to have their profile displayed.</h5>
          </li>
          <li>
          <h5><b>Gold Awards</b> - Contestants with marks above 85</h5>
          </li>
          <li>
          <h5><b>Silver Awards</b> - Contestants with marks above 80</h5>
          </li>
          <li>
          <h5><b>Bronze Awards</b> - Contestants with marks above 75</h5>
          </li>
          <br/>
          <li>
          <h5>Winners will be invited to have their profile photo displayed on our website, and other social media platforms. </h5>
          </li>
          <li>
          <h5>Teachers of our winners will be invited to the Outstanding Music Teacher List, and be invited for an interview to be featured on our magazine. Winners will also be invited to feature in our magazine and displayed in winner posters on our social media.</h5>
          </li>
          <li>
          <h5>Prizes will be in the form of e-certificates<sup>1</sup>.</h5>
          </li>
        </ul>
        
        <br/>


        <div style={{width:"100%",overflowX:"scroll",overflowY:"hidden",msOverflowStyle:"none",scrollbarWidth:"none"}} >
          <table style={{width:"100%"}}>
            <tr>
              <th style={{whiteSpace: "nowrap"}}>&nbsp;&nbsp;Places</th>
            </tr>
            <tr>
              <td style={{border:"1px solid black"}}>Champion</td>
              <td style={{border:"1px solid black"}}>Second Prize</td>
              <td style={{border:"1px solid black"}}>Third Prize</td>
              <td style={{border:"1px solid black"}}>Fourth Place</td>
            </tr>
          </table>


          <br></br>

          <table style={{width:"100%"}}>
            <tr>
              <th style={{whiteSpace: "nowrap"}}>&nbsp;&nbsp;Outstanding Awards</th>
            </tr>
            <tr style={{}}>
              <td style={{border:"1px solid black",textAlign:"center",width:"33%"}}>Gold Award</td>
              <td style={{border:"1px solid black",textAlign:"center",width:"33%"}}>Silver Award</td>
              <td style={{border:"1px solid black",textAlign:"center",width:"33%"}}>Bronze Award</td>
            </tr>
          </table>

          
          <br></br>


          <table style={{width:"100%"}}>
            <tr>
              <th style={{whiteSpace: "nowrap"}}>&nbsp;&nbsp;Teacher Awards</th>
            </tr>
            <tr style={{}}>
              <td style={{border:"1px solid black",textAlign:"center",width:"33%"}}>Active English Educator Award</td>
              <td style={{border:"1px solid black",textAlign:"center",width:"33%"}}>Excellent Mentorship Award</td>
              <td style={{border:"1px solid black",textAlign:"center",width:"33%"}}>Teacher Medal</td>
            </tr>
          </table>

        </div>

        <br/><br/>
        

 
      <table style={{maxWidth:"100%",padding:"0% 1%"}}>
      <tr>
          <td>Mark Range</td>
          <td>Class</td>
      </tr>
      <tr>
          <td>&gt;&nbsp;85 </td>
          <td>Winners and/or Gold Award</td>
      </tr>
      <tr>
          <td>80-85 </td>
          <td>Silver Award</td>
      </tr>
      <tr>
          <td>75-80</td>
          <td>Bronze Award </td>
      </tr>
      <tr>
          <td>&lt;&nbsp;75</td>
          <td>Certificate</td>
      </tr>
    
  </table>


        <br></br>
        <h4 className="apply-body-font">With full mark as 100, contestants will be evaluated with the following rubrics:</h4>
        <ol style={{listStyleType:"upper-roman"}}>
          <li><h4 style={{fontSize:"1.3em"}}>{"Accuracy (20%)"}</h4></li>
          <li><h4 style={{fontSize:"1.3em"}}>{"Voice (20%)"}</h4></li>
          <li><h4 style={{fontSize:"1.3em"}}>{"Interpretation (20%)"}</h4></li>
          <li><h4 style={{fontSize:"1.3em"}}>{"Physical Prescence (20%)"}</h4></li>
          <li><h4 style={{fontSize:"1.3em"}}>{"Overall Performance (20%)"}</h4></li>
          {/* <li><h4 style={{fontSize:"1.3em"}}>Musicality</h4></li>
          <li><h4 style={{fontSize:"1.3em"}}>Style</h4></li>
          <li><h4 style={{fontSize:"1.3em"}}>Tone Color</h4></li>
          <li><h4 style={{fontSize:"1.3em"}}>Presentation</h4></li> */}
        </ol>
        <br></br>
        
    {/* <table style={{maxWidth:"100%",padding:"0% 1%"}}>
    <tr>
        <td>Marks</td>
        <td>Class </td>
    </tr>
    <tr>
        <td>&lt;&nbsp;65 </td>
        <td>Severe impairment of submission’s integrity  </td>
    </tr>
    <tr>
        <td>65-75 </td>
        <td>Below satisfactory </td>
    </tr>
    <tr>
        <td>75-80 </td>
        <td>Satisfactory </td>
    </tr>
    <tr>
        <td>80-85 </td>
        <td>Merit </td>
    </tr>
    <tr>
        <td>85-90 </td>
        <td>Distinction and/or Prizes </td>
    </tr>
    <tr>
        <td>&gt;&nbsp;90 </td>
        <td>Distinction and/or Prizes </td>
    </tr>
   
</table> */}

      {<div style={{marginTop:"6%",fontFamily:"Courgette",fontSize:"1em"}}>
        <p><sup>1</sup><i>For trophies and printed certificates, orders are to be made online after receiving your results.</i></p>
      </div>}
      

      {/* <h1 style={{marginTop:"7%",marginBottom:"1vh"}}>Champion List</h1>
                <br></br> */}

      {/* <div style={{justifyContent:"center",display:"flex",flexDirection:"column",fontSize:"1.3rem"}}>
      <table className="award-laureate-table" >
      {
        Object.keys(laureate).map((key,item)=>{
          return(  
                <tr style={{border:"1px solid white"}}>
                  <td style={{textAlign:"center",borderRight:"1px solid white"}}>{key}</td>
                  <td style={{textAlign:"center"}}>{laureate[key]}</td>
                </tr>    
          )
        })
      }
      </table>
      </div> */}
      

      
      </div>
      </div>
    );
  }
  }
  

export default IESADAwards;
