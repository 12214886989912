import React from 'react'
import { Navbar,Nav,NavDropdown,Form,FormControl,Button,Dropdown } from 'react-bootstrap'

const navsize_iyao = "1.2rem"
const menu = {
    "en":{
        "home":"Home", "about":"About", "magazine":"MAGAZINE & GALLERY", "category":"Category", "apply":"Apply", "awards":"Awards","ourproduct":"Our Products", "dropdown":"Drop Down","rtprice":"Real-time Prices","fnews":"Financial News","histprice":"Historical Data","riskman":"Risk Management","traderecord":"Trade Record",
        "memberrank":"Memberships", "terms":"Rules and Regulations","jury":"Jury","contact":"Contact","winners":"Winners","teacherlist":"OUTSTANDING MUSIC TEACHERS", "scholarship":"Scholarship"   },
    "zh":{
        "home":"主頁", "quote":"實時報價", "aboutus":"關於我們", "awards":"Awards", "ourservice":"我們的業務","ourproduct":"我們的產品", "dropdown":"更多","rtprice":"實時市場價格","fnews":"財經新聞","histprice":"歷史價格圖表","riskman":"風險管理及入市計劃","traderecord":"交易詳情","memberrank":"會員階級制度"
    },
    "cn":{
        "home":"主页", "quote":"实时报价", "aboutus":"关于我们", "awards":"Awards","ourservice":"我们的业务","ourproduct":"我们的产品", "dropdown":"更多","rtprice":"实时市场价格","fnews":"财经新闻","histprice":"历史价格图表","riskman":"风险管理及入市计划","traderecord":"交易详情","memberrank":"会员阶级制度"
    },
}

class NavBarTop extends React.Component{
    constructor(props){
        super(props);
        this.state = {  
          
        };
    
        this.clicknav = this.clicknav.bind(this)

      }
    componentDidMount(){
        document.title="International Young Artist Open Competition"
        var link1 = document.getElementById('favicon')
        var link2 = document.getElementById('touchicon')
        link1.href="/assets/iyao_logo.png"
        link2.href="/assets/iyao_logo.png"
        this.props.setval({comp:"iyao"})
    }
      clicknav(page,pos){
        if(page===this.props.pageSelected){
            document.getElementById(pos).scrollIntoView();
        }else {
            this.props.setval({pageSelected:page,pagepos:pos});           
        } 
      }
//{this.props.companyname[this.props.lang]}
    render(){
        return(
            <div>
                <div className="row">
                    <div className={["col-md-12","fullwidth"].join()}>
                       
                            <Navbar bg="dark" variant="dark" expand="lg" sticky="top">
                                <Navbar.Brand className="navbar-brand" href="/"><img src="/assets/iyao_logo.png" width="150px" height="110px"/></Navbar.Brand>
                                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                                <Navbar.Collapse className="navbar-collapse" id="basic-navbar-nav">
                                    <Nav className="mr-auto">
                                    {/* <Nav.Link style={{fontFamily:"Courgette",fontSize:navsize_iyao,color:"white",fontWeight:"bold",marginLeft:"10px"}} href="/iyao" onClick={(e)=>{
                                        //this.props.setval({pageSelected:"home"})
                                        }}>{menu[this.props.lang]["home"]}</Nav.Link> */}

                                        <NavDropdown style={{fontFamily:"Optima",fontWeight:"bold",fontSize:navsize_iyao}} title={menu[this.props.lang]["home"]}>
                                         <NavDropdown.Item href="/iyao" >{menu[this.props.lang]["home"]}</NavDropdown.Item>
                                            <NavDropdown.Item href="/iyao/magazine" >{menu[this.props.lang]["magazine"]}</NavDropdown.Item>
                                        </NavDropdown>

                                    <Nav.Link style={{fontFamily:"Courgette",fontSize:navsize_iyao,color:"white",fontWeight:"bold",marginLeft:"10px"}} href="/iyao/about" onClick={(e)=>{
                                        //this.props.setval({pageSelected:"about"})
                                        }}>{menu[this.props.lang]["about"]}</Nav.Link>
                                    <Nav.Link style={{fontFamily:"Courgette",fontSize:navsize_iyao,color:"white",fontWeight:"bold",marginLeft:"10px"}} href="/iyao/apply"onClick={(e)=>{
                                        //this.props.setval({pageSelected:"apply"})
                                        }}>{menu[this.props.lang]["apply"]}</Nav.Link>
                                    <Nav.Link style={{fontFamily:"Courgette",fontSize:navsize_iyao,color:"white",fontWeight:"bold",marginLeft:"10px"}} href="/iyao/category" onClick={(e)=>{
                                        //this.props.setval({pageSelected:"category"})
                                        }}>{menu[this.props.lang]["category"]}</Nav.Link>
                                    <Nav.Link style={{fontFamily:"Courgette",fontSize:navsize_iyao,color:"white",fontWeight:"bold",marginLeft:"10px"}} href="/iyao/awards" onClick={(e)=>{
                                        //this.props.setval({pageSelected:"awards"})
                                        }}>{menu[this.props.lang]["awards"]}</Nav.Link>
                                    <Nav.Link style={{fontFamily:"Courgette",fontSize:navsize_iyao,color:"white",fontWeight:"bold",marginLeft:"10px"}} href="/iyao/terms" onClick={(e)=>{
                                        //this.props.setval({pageSelected:"terms"})
                                        }}>{menu[this.props.lang]["terms"]}</Nav.Link>
                                    <Nav.Link style={{fontFamily:"Courgette",fontSize:navsize_iyao,color:"white",fontWeight:"bold",marginLeft:"10px"}} href="/iyao/jury" onClick={(e)=>{
                                        //this.props.setval({pageSelected:"jury"})
                                        }}>{menu[this.props.lang]["jury"]}</Nav.Link>
                                    <Nav.Link style={{fontFamily:"Courgette",fontSize:navsize_iyao,color:"white",fontWeight:"bold",marginLeft:"10px"}} href="/iyao/contact" onClick={(e)=>{
                                        //this.props.setval({pageSelected:"jury"})
                                        }}>{menu[this.props.lang]["contact"]}</Nav.Link>
                                    {/* <Nav.Link style={{fontFamily:"Courgette",fontSize:navsize_iyao,color:"white",fontWeight:"bold",marginLeft:"10px"}} href="/iyao/winners" onClick={(e)=>{
                                        //this.props.setval({pageSelected:"jury"})
                                        }}>{menu[this.props.lang]["winners"]}</Nav.Link> */}
                                   <NavDropdown style={{fontFamily:"Optima",fontSize:navsize_iyao,color:"white",fontWeight:"bold",marginLeft:"10px"}} title={menu[this.props.lang]["winners"]}>
                                        <NavDropdown.Item style={{fontSize:"0.9rem"}} href="/iyao/winners" onClick={(e)=>{
                                            localStorage.setItem("winneryear", "2024");
                                            }}>{"2024 "+menu[this.props.lang]["winners"]}</NavDropdown.Item>
                                        <NavDropdown.Item style={{fontSize:"0.9rem"}} href="/iyao/winners" onClick={(e)=>{
                                            localStorage.setItem("winneryear", "2023");
                                            }}>{"2023 "+menu[this.props.lang]["winners"]}</NavDropdown.Item>
                                        <NavDropdown.Item style={{fontSize:"0.9rem"}} href="/iyao/winners" onClick={(e)=>{
                                            localStorage.setItem("winneryear", "2022");
                                            }}>{"2022 "+menu[this.props.lang]["winners"]}</NavDropdown.Item>
                                        <NavDropdown.Item style={{fontSize:"0.8rem"}} href="/iyao/teacherlist" >{menu[this.props.lang]["teacherlist"]}</NavDropdown.Item>
                                    </NavDropdown>

                                    <Nav.Link style={{fontFamily:"Courgette",fontSize:navsize_iyao,color:"white",fontWeight:"bold",marginLeft:"10px"}} href="/iyao/musicscholarship" onClick={(e)=>{
                                        //this.props.setval({pageSelected:"jury"})
                                        }}>{menu[this.props.lang]["scholarship"]}</Nav.Link>

                                    <img src="/assets/yt_logo.PNG" className={"nav-ytlogo"} style={{marginLeft:"20px"}} height={"65px"} alt={"YT_LOGO"} />
                                   
                                   
                                    </Nav>
                                   
                                </Navbar.Collapse>
                            </Navbar>
                         
                    </div>
                </div>
            </div>
        )  
    }
}

export default NavBarTop;