import '../../App.css';
import React, {Component, Container} from 'react';
import {Row,Col,Image,Navbar, Nav, NavItem, NavDropdown } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import YoutubeBackground from 'react-youtube-background';
import Card from './card';
import NavBarTop from "./navbar";
import Footer from "./footer";

var count = []

class IYFAOMagazine extends Component {
  constructor(props){
    super(props);
    this.state = {
       
    };

    this.setval = this.setval.bind(this)
  }

  setval(obj){
    this.setState(obj)
  }
  componentDidMount(){
    document.title="International Young Fine Art Artist Open Competition"
    var link1 = document.getElementById('favicon')
    var link2 = document.getElementById('touchicon')
    link1.href="/assets/about-iyfao.png"
    link2.href="/assets/about-iyfao.png"
    this.props.setval({comp:"iyfao"})  
  }      
      
  render(){
    for(let i=1;i<=12;i++){
      count[i] = i

    }
    return (
      <div>

         {
           count.map((item)=>{
            return  <div className={"magazine-wrapper"}>
                <img src={"../assets/iyfaomagazine/IMG_"+parseInt(item)+".PNG"} className='magazine-img'/>
            </div>
           })
         }  

      </div>
    );
  }
  }
  

export default IYFAOMagazine;
