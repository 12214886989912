import '../../App.css';
import React, {Component, Container} from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import {Row,Col,Image,Navbar, Nav, NavItem, NavDropdown } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import YoutubeBackground from 'react-youtube-background';
import Card from './card';
import NavBarTop from "./navbar";
import Footer from "./footer";
import IYAOScholarshipCheckout from "./elements/scholarshipcheckout";

var count = []
const requesturl = "https://docs.google.com/forms/d/e/1FAIpQLSek6WjU-MS-o8SJ729FQ_vcPtFGsCND6fVY3aiv1rpjfoK5kQ/viewform?usp=dialog"

class IYAOScholarshipPayment extends Component {
  constructor(props){
    super(props);
    this.state = {
        chosengroup:null,
        chosenprice:1500,
        applicantName:"",
        product:{},
        customPrice:null,
        discountTitle:"Basic",
        paymentTitle:"Initial Payment",
       text:{
       
            }
    };

    this.setval = this.setval.bind(this)
    this.setAmount = this.setAmount.bind(this)
    this.addDiscount = this.addDiscount.bind(this)
    this.changeName = this.changeName.bind(this)
  }

  setval(obj){
    this.setState(obj)
  }
  componentDidMount(){
    document.title="International Young Artist Open Competition"
    var link1 = document.getElementById('favicon')
    var link2 = document.getElementById('touchicon')
    link1.href="/assets/iyao_logo.png"
    link2.href="/assets/iyao_logo.png"
    this.props.setval({comp:"iyao"})
  }   
  changeName(e){
    const chosene = e.target.value.toUpperCase();
    this.setState({applicantName:chosene})

  }
  setAmount(e,type){
    var amt = 1500
    if (type === "Remaining Balance"){
        amt = 2488
    } 
    this.setState({chosenprice:amt,paymentTitle:type})
  }
  addDiscount(e,discountAmt,newTitle){
    if(this.state.paymentTitle === "Remaining Balance"){
        var price = 2488
        price = price - discountAmt
       this.setState({chosenprice:price,discountTitle:newTitle})
    }
  

  }

  clickapply(){
    window.open(requesturl, '_blank', 'noopener,noreferrer');
}   
      
  render(){
    for(let i=1;i<=6;i++){
      count[i] = i

    }
    return (
      <div>

        <div>
            <img src="/assets/iyao-scholarship-banner.jpg" alt="" width="100%" style={{borderRadius:"0px"}}/>
        </div>

        <div className="home-card" style={{width:"95vw",padding:"40px"}}>
            <h1>Leon Masterclass Program - Payment</h1>
          <div className="cardcontent" style={{fontSize:"0.9rem"}}>
          <div style={{display:"flex",flexDirection:"column",alignItems:"left",padding:"5px",border:"1px solid white",background:"rgba(255,255,255,0.9)",paddingLeft:"5%",paddingRight:"5%",color:"#1c1f1d",borderRadius:"10px"}}>
            
            <h2  style={{marginLeft:"1%",marginTop:"2%",marginBottom:"3%"}}>Pay now with Credit Card or PayPal 
              <p style={{fontSize:"0.56em",textAlign:"justify"}}>*Service charge may be applied. Please double check your amount in the paypal pop-up window. Remember to take a screenshot after successful payment. 
                If you forgot, you may take a screenshot of your payment receipt email from PayPal.</p></h2>
            
            <label style={{marginLeft:"1.7%"}}><h3 style={{fontSize:"1.2em"}}>Applicant Name &nbsp;&nbsp; 
            <input style={{width:"60vw"}} type="text" name="Applicant Name" onChange={(e)=>{
              this.changeName(e)
              }} />
            </h3>
             </label>

             <label style={{marginLeft:"2%",minHeight:"40px",display:"flex",flexDirection:"row",alignItems:"center"}}>
                <h3 style={{fontSize:"1.2em",float:"left"}}>Payment Type &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  </h3>
             <DropdownButton id="dropdown-basic-button" title={this.state.paymentTitle} style={{float:"left"}}>
                <Dropdown.Item onClick={(e)=>{
                    this.setAmount(e,"Initial Payment")
                }}>{"Initial Payment"}</Dropdown.Item>
                <Dropdown.Item onClick={(e)=>{
                    this.setAmount(e,"Remaining Balance")
                }}>{"Remaining Balance"}</Dropdown.Item> 
            </DropdownButton>
           
             </label>


             <label style={{marginLeft:"2%",minHeight:"40px",display:"flex",flexDirection:"row",alignItems:"center"}}>
                <h3 style={{fontSize:"1.2em",float:"left"}}>Discount Type &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  </h3>
             <DropdownButton disabled={(this.state.paymentTitle==="Initial Payment") ? true : null} id="dropdown-basic-button" title={this.state.discountTitle} style={{float:"left"}}>
                <Dropdown.Item onClick={(e)=>{
                    this.addDiscount(e,0,"Basic")
                }}>{"Basic"}</Dropdown.Item>
                <Dropdown.Item onClick={(e)=>{
                    this.addDiscount(e,500,"Basic + Gold and/or Silver Award")
                }}>{"Basic + Gold and/or Silver Award"}</Dropdown.Item> <Dropdown.Item onClick={(e)=>{
                    this.addDiscount(e,1000,"Basic + Champion / 2nd Prize / 3rd Prize / 4th Prize")
                }}>{"Basic + Champion / 2nd Prize / 3rd Prize / 4th Prize"}</Dropdown.Item>
            </DropdownButton>
           
             </label>


                      
            <label>

                {
                  ((typeof(this.state.chosenprice)=="number")) && 
                  <div style={{marginTop:"2%",marginLeft:"2%",marginRight:"2%",fontSize:"1.5rem"}}>
                    <i>{"Application Fee : EUR "+this.state.chosenprice}</i>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <h6 style={{fontSize:"0.65em",textAlign:"justify",marginRight:"7%"}}>*You might be able to pay in other currencies. Please check your options after pressing the pay buttons below.</h6></div>
                }
              
            </label>

            <div style={{marginTop:"3%",marginLeft:"10%",marginRight:"10%"}}>
         <IYAOScholarshipCheckout {...this.state}/>
          </div>

          
          </div>
          </div>

          <div style={{display:"flex",flexDirection:"column",marginLeft:"20%",marginRight:"20%"}}>
                  <a><button className="about-goldbutton" style={{width:"100%"}} onClick={(e)=>{
                      window.open(requesturl, '_blank', 'noopener,noreferrer');
                  }}>{"Fill In Application Form"}</button></a>
                  </div>
          
        </div>

       

      </div>
    );
  }
  }
  

export default IYAOScholarshipPayment;
